import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './BroadSignRejectCreationModal.scss'
import Modal from '../../../../components/Modal'
import Button, { ButtonTheme } from '../../../../components/Form/Button'
import { BroadSignCreationModel } from '../../../../api/broadSign/models/broad-sign-creation.model'
import BroadSignService from '../../services/broad-sign.service'
import { AppContext } from '../../../../contexts/AppContext'

const BroadSignRejectCreationModal: React.FC<{
  creation?: BroadSignCreationModel
  isModalOpen: boolean
  setModalOpen: (open: boolean) => void
}> = ({ creation, isModalOpen, setModalOpen }) => {
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)
  const [note, setNote] = useState('')

  const rejectCreation = () => {
    if (!creation) {
      return
    }

    void BroadSignService.rejectCreation(creation.id, note, addNotification, t, setModalOpen)
  }

  return (
    <Modal
      title={`${t('broadSign.rejectionReason')}`}
      classNameOverlay='BroadSignRejectCreationModal'
      isOpen={isModalOpen}
      onClose={(isOpen: boolean) => void setModalOpen(isOpen)}
      footer={
        <div className={'BroadSignRejectCreationModal__footer'}>
          <Button
            theme={ButtonTheme.BLUE}
            disabled={!note || note.length < 5}
            onClick={rejectCreation}
          >
            {t('common.save')}
          </Button>
          <Button
            theme={ButtonTheme.BLUE_OUTLINE}
            onClick={() => void setModalOpen(false)}
          >
            {t('common.cancel')}
          </Button>
        </div>
      }
    >
      {isModalOpen && (
        <div>
          <textarea
            autoFocus
            className='Input__field BroadSignRejectCreationModal__input'
            onChange={event => setNote(event.target.value)}
            value={note}
          />
        </div>
      )}
    </Modal>
  )
}

export default BroadSignRejectCreationModal
