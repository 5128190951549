import { gql } from '@apollo/client'

export const BROADSIGN_ACCEPT_CREATION = gql`
  mutation BroadsignAcceptCreation($id: ID!) {
    broadsignAcceptCreation(input: { id: $id }) {
      broadsignCreation {
        id
        status
      }
      errors
    }
  }
`
