import campaign from './campaign'
import creation from './creation'
import agency from './agency'
import user from './user'
import roles from './roles'
import media from './media'
import report from './report'
import inventory from './inventory'
import companies from './companies'
import emissionPlan from './emissionPlan'
import broadSign from './broadSign'

export default {
  campaign,
  creation,
  agency,
  user,
  roles,
  media,
  report,
  inventory,
  companies,
  emissionPlan,
  broadSign,
}
