import { gql } from '@apollo/client'

export const BROADSIGN_UPDATE_CAMPAIGN_BY_DEAL = gql`
  mutation BroadsignUpdateCampaignByDeal($dealId: Int!, $campaignId: ID) {
    broadsignUpdateCampaignByDeal(input: { dealId: $dealId, campaignId: $campaignId }) {
      campaign {
        id
        dealId
        dealExternalId
        name
        briefName
        status
        startDate
        endDate
        uuid
        mediaCriteriaSearch {
          mediumFormat
          pois
        }
        target
        offer
        brainState
        priority
        budget
        target
        impressions
        audience
        hiddenImpressions
        hiddenImpressionsActive
        hiddenAudience
        hiddenAudienceActive
        commercialAttribute
        emissionType
        priorityRanges
        mediaDuration
      }
      errors
    }
  }
`
