import api from '../../../../../api'
import { UpdateCampaignByDealModel } from '../models/update-campaign-by-deal.model'
import { Routes } from '../../../../../routes'
import { NavigateFunction } from 'react-router-dom'

export default class BroadSignAddModalService {
  static broadSignUpdateCampaignByDeal = (
    payload: UpdateCampaignByDealModel,
    navigate: NavigateFunction
  ): Promise<unknown> => {
    return api.broadSign.broadSignUpdateCampaignByDeal(payload).then(response => {
      if (!response.data?.broadsignUpdateCampaignByDeal) {
        return
      }

      const campaign = response.data.broadsignUpdateCampaignByDeal.campaign

      void navigate(Routes.CAMPAIGNS.EDIT(campaign.id))
    })
  }
}
