import { gql } from '@apollo/client'

export const BROADSIGN_REJECT_CREATION = gql`
  mutation BroadsignRejectCreation($id: ID!, $rejectionReason: String!) {
    broadsignRejectCreation(input: { id: $id, rejectionReason: $rejectionReason }) {
      broadsignCreation {
        id
        status
      }
      errors
    }
  }
`
