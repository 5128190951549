import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  faBuilding,
  faChartColumn,
  faClipboardList,
  faFile,
  faSquareArrowUpRight,
} from '@fortawesome/free-solid-svg-icons'
import NavbarItem from './NavbarItem/NavbarItem'
import { Routes } from 'routes'
import { AppContext } from 'contexts/AppContext'
import { PAGES } from 'constant/authorization'
import { AppMode } from 'utils/env'
import './Navbar.scss'
import NavbarSubItem from './NavbarSubItem/NavbarSubItem'
import DropdownMenu from './DropdownMenu'
import { PermissionType, FeatureName, CampaignPermissionType } from '../../../types/features'

const Navbar: React.FC = () => {
  const { allowedFor } = useContext(AppContext)
  const { t } = useTranslation()
  const getVersion = () =>
    process.env.REACT_APP_CUSTOM_NODE_ENV === AppMode.DEVELOPMENT ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === AppMode.STAGING ||
    process.env.REACT_APP_CUSTOM_NODE_ENV === AppMode.DEV
      ? 'Demo'
      : 'Beta'

  return (
    <div className={'Navbar__container'}>
      <div className='Navbar'>
        {allowedFor({
          feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Read },
        }) && (
          <NavbarItem
            to={Routes.CAMPAIGNS.ALL}
            icon={faClipboardList}
            title={t('common.campaigns')}
          >
            {t('common.campaigns')}
          </NavbarItem>
        )}

        {allowedFor({
          template: PAGES.AGENCIES.LIST,
          feature: { name: FeatureName.Agencies, action: PermissionType.Read },
        }) && (
          <NavbarItem
            to={Routes.AGENCIES.ALL}
            icon={faBuilding}
            title={t('agencies.agencies')}
          >
            {t('agencies.agencies')}
          </NavbarItem>
        )}

        {allowedFor({
          template: PAGES.USERS.LIST,
          feature: { name: FeatureName.Users, action: PermissionType.Read },
        }) && (
          <DropdownMenu
            headerTitle={t('user.all.users')}
            subPages={[Routes.USERS.ALL, Routes.ROLES.ALL]}
          >
            <NavbarSubItem
              dataCy={'user-list-btn'}
              to={Routes.USERS.ALL}
              title={t('user.all.list')}
            >
              {t('user.all.list')}
            </NavbarSubItem>
            <NavbarSubItem
              to={Routes.ROLES.ALL}
              title={t('user.roles')}
            >
              {t('user.roles')}
            </NavbarSubItem>
          </DropdownMenu>
        )}

        {allowedFor({
          template: PAGES.REPORT,
          feature: { name: FeatureName.Reports, action: PermissionType.Active },
        }) && (
          <NavbarItem
            to={Routes.REPORT}
            icon={faFile}
            title={t('report.report')}
          >
            {t('report.report')}
          </NavbarItem>
        )}

        {allowedFor({
          template: PAGES.BROAD_SIGN,
          feature: { name: FeatureName.BroadsignCreations, action: PermissionType.Active },
        }) && (
          <NavbarItem
            to={Routes.BROADSIGN}
            icon={faSquareArrowUpRight}
            title={t('broadSign.title')}
          >
            {t('broadSign.title')}
          </NavbarItem>
        )}

        {allowedFor({
          template: PAGES.INVENTORY,
          feature: { name: FeatureName.Inventory, action: PermissionType.Active },
        }) && (
          <NavbarItem
            to={Routes.INVENTORY}
            icon={faChartColumn}
            title={t('inventory.title')}
          >
            {t('inventory.title')}
          </NavbarItem>
        )}
      </div>

      <div className='Navbar__version'>{getVersion()}</div>
    </div>
  )
}

export default Navbar
