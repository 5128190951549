import { FormErrors } from 'types/various'

export interface BroadSignFormModel {
  dealId: string
  campaignId: string
}

export const broadSignFormDefault: BroadSignFormModel = {
  dealId: '',
  campaignId: '',
}

export const broadSignFormErrorsDefault: FormErrors = {
  dealId: [],
  campaignId: [],
}
