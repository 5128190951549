import { store } from '../../../store'
import { BroadSignFiltersModel } from '../../../api/broadSign/models/broad-sign-filters.model'
import { broadSignActions } from '../store/broad-sign-slice'
import api from '../../../api'
import { NotifierType } from '../../../components/Notifier'

export default class BroadSignService {
  static fetchBroadSignCreations = (
    filters: BroadSignFiltersModel,
    pageNumber: number
  ): Promise<unknown> => {
    return Promise.resolve(store.dispatch(broadSignActions.setPageNumber(pageNumber)))
      .then(() => Promise.resolve(store.dispatch(broadSignActions.setLoading(true))))
      .then(() => {
        const pageToFirst = pageNumber * 10

        return api.broadSign.fetchBroadSignCreations(filters, pageToFirst)
      })
      .then(response => {
        if (!response.data?.broadsignCreations) {
          return
        }

        return store.dispatch(
          broadSignActions.setData({
            data: [...response.data.broadsignCreations.nodes],
            totalItemsCount: response.data.broadsignCreations.totalCount,
          })
        )
      })
      .catch(() => [])
      .finally(() => void store.dispatch(broadSignActions.setLoading(false)))
  }

  static acceptCreation = (
    id: string,
    addNotification: (type: NotifierType, text: string | string[]) => void,
    t: (key: string) => string
  ): Promise<unknown> => {
    return api.broadSign
      .acceptCreation(id)
      .then(response => {
        if (response.data?.broadsignAcceptCreation.broadsignCreation.status !== 'accepted') {
          return
        }

        void store.dispatch(broadSignActions.setCreationStatus({ id, status: 'accepted' }))

        addNotification(NotifierType.SUCCESS, t('broadSign.creationAccepted'))
      })
      .catch(() => [])
  }

  static rejectCreation = (
    id: string,
    rerejectionReason: string,
    addNotification: (type: NotifierType, text: string | string[]) => void,
    t: (key: string) => string,
    setModalOpen: (open: boolean) => void
  ): Promise<unknown> => {
    return api.broadSign.rejectCreation(id, rerejectionReason).then(response => {
      if (response.data?.broadsignRejectCreation.broadsignCreation.status !== 'rejected') {
        return
      }

      void store.dispatch(broadSignActions.setCreationStatus({ id, status: 'rejected' }))

      addNotification(NotifierType.SUCCESS, t('broadSign.creationRejected'))

      setModalOpen(false)
    })
  }
}
