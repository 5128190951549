import { Errors } from 'components/Form/InputError'
import { TableSortColumn } from 'components/Table'
import { Campaign, Creation } from './campaign'
import { BrainState } from '../hooks/useMediaSelection/types'

export type OptionalParameters<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>
export type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Sortable {
  [key: string]: any
}

export enum FormType {
  NEW,
  EDIT,
}

export type ReactRouterParams = {
  id?: string
  uuid?: string
}

export interface MutationStateResponse {
  brainState?: BrainState
  errors: string[]
  success: boolean
}

export interface BroadSignUpdateCampaignByDealResponse {
  campaign: Campaign
  errors: string[]
}

export interface PriceReportResponse {
  currentPricingReport: {
    audience: {
      headers: string[]
      content: string[][]
    }
    impressions: {
      headers: string[]
      content: string[][]
    }
  }
}

export type FormErrors = Record<string, Errors>

export interface PaginationProps {
  first: number
  last: number
  sortColumn: TableSortColumn
  sortDirection: SortDirection
  term?: string
}

export type CampaignAndCreationPubPrivIds = {
  campaignId?: Campaign['id']
  campaignUuid?: Campaign['uuid']
  creationId?: Creation['id']
  creationUuid?: Creation['id']
}

export type Primitive = string | number | bigint | boolean | undefined | symbol | null
