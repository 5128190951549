import Card from 'components/Layout/Card'
import PageHeader from 'components/Layout/PageHeader'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './Broadsign.scss'
import BroadsignFilters from './containers/BroadSignFilters'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store'
import TsxUtils from '../../utils/tsx'
import FillingSpinner, { FillingSpinnerType } from '../../components/FillingSpinner'
import BroadSignCreationsTable from './components/BroadSignCreationsTable'
import debounce from 'lodash/debounce'
import BroadSignService from './services/broad-sign.service'
import BroadSignCreationModal from './containers/BroadSignCreationModal'
import { broadSignActions } from './store/broad-sign-slice'
import { BroadSignCreationModel } from '../../api/broadSign/models/broad-sign-creation.model'

const Broadsign: React.FC = () => {
  const debounceTime = 2000
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { loading, pageNumber, totalItemsCount, data, filters, selectedCreation } = useSelector(
    (state: RootState) => state.broadSign
  )
  const debouncedFetchBroadSignCreations = useCallback(
    debounce(BroadSignService.fetchBroadSignCreations, debounceTime),
    []
  )

  useEffect(() => {
    const firstPage = 1

    void debouncedFetchBroadSignCreations(filters, firstPage)
  }, [filters])

  useEffect(() => {
    // Reset state on unmount/destroy
    return () => {
      dispatch(broadSignActions.resetState())
    }
  }, [])

  const setPageNumber = (page: number) => {
    void BroadSignService.fetchBroadSignCreations(filters, page)
  }

  const closeCreationModal = () => {
    void dispatch(broadSignActions.setSelectedCreation(undefined))
  }

  const onRowClick = (creation?: BroadSignCreationModel) => {
    void dispatch(broadSignActions.setSelectedCreation(creation))
  }

  return (
    <div className={'Broadsign__container'}>
      <PageHeader>{t('broadSign.creations')}</PageHeader>

      <BroadsignFilters />

      <Card className={TsxUtils.extraStyle(loading, 'Broadsign--loading')}>
        {loading ? (
          <FillingSpinner type={FillingSpinnerType.STANDARD} />
        ) : (
          <BroadSignCreationsTable
            pageNumber={pageNumber}
            totalItemsCount={totalItemsCount}
            data={data}
            rowClick={onRowClick}
            setPageNumber={setPageNumber}
          />
        )}
        <BroadSignCreationModal
          creation={selectedCreation}
          closeClick={closeCreationModal}
        />
      </Card>
    </div>
  )
}

export default Broadsign
