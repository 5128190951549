import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BroadSignSliceModel } from '../models/broad-sign-slice.model'
import { produce } from 'immer'

const defaultState: BroadSignSliceModel = {
  showFilters: false,
  loading: true,
  pageNumber: 1,
  filters: {
    text: '',
    statuses: [],
    dateFrom: '',
    dateTo: '',
  },
  dropdowns: {
    statuses: ['accepted', 'pending_acceptation', 'rejected'],
  },
  data: [],
  selectedCreation: undefined,
  totalItemsCount: 0,
}

const initialState: BroadSignSliceModel = produce(defaultState, draft => draft)

const broadSignSlice = createSlice({
  name: 'broadSign',
  initialState,
  reducers: {
    toggleFiltersVisibility(state: BroadSignSliceModel) {
      state.showFilters = !state.showFilters
    },
    setFilters(state: BroadSignSliceModel, action: PayloadAction<BroadSignSliceModel['filters']>) {
      state.filters = action.payload
    },
    setLoading(state: BroadSignSliceModel, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setPageNumber(state: BroadSignSliceModel, action: PayloadAction<number>) {
      state.pageNumber = action.payload
    },
    setData(
      state: BroadSignSliceModel,
      action: PayloadAction<{ data: BroadSignSliceModel['data']; totalItemsCount: number }>
    ) {
      state.data = action.payload.data
      state.totalItemsCount = action.payload.totalItemsCount
    },
    setSelectedCreation(
      state: BroadSignSliceModel,
      action: PayloadAction<BroadSignSliceModel['selectedCreation']>
    ) {
      state.selectedCreation = action.payload
    },
    setCreationStatus(
      state: BroadSignSliceModel,
      action: PayloadAction<{ id: string; status: 'accepted' | 'rejected' }>
    ) {
      state.data = state.data.map(creation => {
        return creation.id === action.payload.id
          ? { ...creation, status: action.payload.status }
          : creation
      })

      if (state.selectedCreation && state.selectedCreation.id === action.payload.id) {
        state.selectedCreation.status = action.payload.status
      }
    },
    resetState() {
      return defaultState
    },
  },
})

export const broadSignActions = broadSignSlice.actions

export default broadSignSlice
