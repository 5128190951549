import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import './BroadSignCreationModal.scss'
import Modal from '../../../../components/Modal'
import { BroadSignCreationModel } from '../../../../api/broadSign/models/broad-sign-creation.model'
import Button, { ButtonTheme } from '../../../../components/Form/Button'
import DateUtils from '../../../../utils/date'
import { DATE_FORMAT } from '../../../../constant'
import BroadSignService from '../../services/broad-sign.service'
import { AppContext } from '../../../../contexts/AppContext'
import BroadSignRejectCreationModal from '../BroadSignRejectCreationModal/BroadSignRejectCreationModal'

const BroadSignCreationModal: React.FC<{
  creation?: BroadSignCreationModel
  closeClick: (open: boolean) => void
}> = ({ creation, closeClick }) => {
  const { t } = useTranslation()
  const { addNotification } = useContext(AppContext)
  const [showRejectionModal, setShowRejectionModal] = React.useState(false)

  const acceptCreation = () => {
    if (!creation) {
      return
    }

    void BroadSignService.acceptCreation(creation.id, addNotification, t)
  }

  return (
    <Modal
      title={`${t('broadSign.creation')}${creation?.details ? ': ' + creation.details : ''}`}
      classNameOverlay='BroadSignCreationModal'
      isOpen={!!creation}
      onClose={(isOpen: boolean) => void closeClick(isOpen)}
      footer={
        <div className={'BroadSignCreationModal__footer'}>
          <Button
            theme={ButtonTheme.BLUE}
            onClick={acceptCreation}
            disabled={creation?.status === 'accepted'}
          >
            {t('broadSign.approve')}
          </Button>
          <Button
            theme={ButtonTheme.BLUE_OUTLINE}
            onClick={() => setShowRejectionModal(true)}
            disabled={creation?.status === 'rejected'}
          >
            {t('broadSign.reject')}
          </Button>
        </div>
      }
    >
      {creation && (
        <>
          <div className={'BroadSignCreationModal__container'}>
            <img
              className={'BroadSignCreationModal__image'}
              src={creation.file.url}
              alt={creation.file.filename}
            />
            <div>
              <p className={'BroadSignCreationModal__header'}>
                {t('broadSign.creation')}: {creation?.details}
              </p>
              <div className={'BroadSignCreationModal__details-container'}>
                <p>{t<string>('broadSign.id')}:</p>
                <p>{creation.id}</p>
                <p>{t<string>('broadSign.mediaFormat')}:</p>
                <p>{creation.mediaFormat ?? '-'}</p>
                <p>{t<string>('broadSign.duration')}:</p>
                <p>{creation.duration}</p>
                <p>{t<string>('broadSign.resolution')}:</p>
                <p>{creation.width + 'x' + creation.height + 'px'}</p>
                <p>{t<string>('broadSign.createdAt')}:</p>
                <p>{DateUtils.parseAndFormat(creation.createdAt, DATE_FORMAT)}</p>
                <p>{t<string>('broadSign.status')}:</p>
                <p>{t<string>(`broadSign.${creation.status}`)}</p>
              </div>
            </div>
          </div>
          <BroadSignRejectCreationModal
            creation={creation}
            isModalOpen={showRejectionModal}
            setModalOpen={setShowRejectionModal}
          />
        </>
      )}
    </Modal>
  )
}

export default BroadSignCreationModal
