import React, {
  CSSProperties,
  MouseEvent,
  ReactChild,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Campaign, CurrentCampaign } from './types'
import FillingSpinner, { FillingSpinnerType } from 'components/FillingSpinner'
import Pagination from 'components/Pagination'
import { Routes } from 'routes'
import { useTranslation } from 'react-i18next'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import DateUtils from 'utils/date'
import { DATE_FORMAT } from 'constant'
import { faCheck, faEdit, faSignsPost, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Alert,
  AlertType,
  CampaignAcceptanceStatus,
  CampaignProgress,
  CampaignProgressStatus,
  CampaignSortColumn,
  CampaignStatus,
} from 'types/campaign'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Form/Button'
import CampaignUtils from 'utils/campaign'
import IconBox from 'components/Icon/IconBox'
import ModalAction from 'components/Modal/ModalAction'
import { AppContext } from 'contexts/AppContext'
import { CAMPAIGN, CAMPAIGN_ACTION } from 'constant/authorization'
import PageHeader from 'components/Layout/PageHeader'
import IconAlert from 'components/Icon/IconAlert'
import Colors from 'styles/colors.module.scss'
import Tooltip from 'components/Tooltip'
import CampaignTableFilters from './CampaignTableFilters'
import { Filter } from 'hooks/useTableFilter'
import service from './service'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import CampaignTableFiltersService from './CampaignTableFilters/service'
import './CampaignTable.scss'
import { BaseRole } from '../../../types/user'
import { CampaignPermissionType, FeatureName } from 'types/features'
import { ActionButtonsHelper } from '../../../utils/action-buttons-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BroadSignAddModal from './BroadSignAddModal'

const ITEMS_PER_PAGE = 100

const CampaignTable: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getTableFilter, setTableFilter, allowedFor, userData } = useContext(AppContext)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [trigger, setTrigger] = useState<boolean>(false)
  const [currentCampaign, setCurrentCampaign] = useState<CurrentCampaign>()
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
  const [editModalIsOpenForCampaign, setEditModalIsOpenForCampaign] = useState<Campaign['id']>()
  const [removeCampaignLoading, setRemoveCampaignLoading] = useState<boolean>(false)
  const tableFilter = getTableFilter(Filter.CAMPAIGN)
  const [hoverProgressForCampaign, setHoverProgressForCampaign] = useState<Campaign['id']>()
  const [hoverAgencyName, setHoverAgencyName] = useState<Campaign['id']>()
  const [isBroadSignModalOpen, setIsBroadSignOpen] = useState<boolean>(false)

  const [hoverAlertForCampaign, setHoverAlertForCampaign] = useState<{
    campaignId: Campaign['id']
    alertType: AlertType
  }>()
  const [hoverForDisabledEdit, setHoverForDisabledEdit] = useState<Campaign['id'] | null>(null)

  const { filters } = useSelector((rs: RootState) => rs.campaignTableFilters)
  const { addNotification } = useContext(AppContext)

  const getCampaigns = (): void =>
    service.getCampaigns(
      {
        pageNumber,
        itemsPerPage: ITEMS_PER_PAGE,
        setCampaigns,
        setTotalCount,
        setLoading,
        setTrigger,
        tableFilter,
        filters: CampaignTableFiltersService.pickOnlyApiFilters(filters),
      },
      t
    )

  useEffect(() => {
    if (trigger) {
      getCampaigns()
    }
  }, [trigger])

  useEffect(() => {
    getCampaigns()
  }, [pageNumber, tableFilter])

  const shouldDisableInSketch = (
    campaignStatus: Campaign['status'],
    acceptanceStatus: Campaign['acceptanceStatus']
  ) => {
    return (
      !allowedFor({
        template: CAMPAIGN_ACTION.EDIT_IN_PENDING_ACCEPTANCE_NOT_BY_CLIENT,
        status: campaignStatus,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
      }) && acceptanceStatus === CampaignAcceptanceStatus.pending_acceptation
    )
  }
  const shouldDisableEditingForClient = (
    campaignStatus: Campaign['status'],
    acceptanceStatus: Campaign['acceptanceStatus']
  ) => {
    return (
      shouldDisableInSketch(campaignStatus, acceptanceStatus) ||
      (allowedFor({
        template: CAMPAIGN_ACTION.EDIT_ACTIVE_PLANNED_RESERVED_CAMPAIGN_BY_CLIENT,
        status: campaignStatus,
        feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
      }) &&
        !userData.user?.roles.some(role => role.baseRole === BaseRole.ADMIN))
    )
  }
  const Status = (
    campaignStatus: Campaign['status'],
    acceptanceStatus: Campaign['acceptanceStatus']
  ): ReactChild => {
    const parsedStatus = CampaignUtils.parseStatus(campaignStatus, acceptanceStatus)

    return (
      <div className='CampaignTable__status'>
        <IconBox
          className='CampaignTable__status--icon'
          icon={parsedStatus.icon}
          theme={parsedStatus.iconTheme}
        />
        {parsedStatus.text}
      </div>
    )
  }

  const Alert = (alerts: Campaign['alerts'], campaignId: Campaign['id']): ReactChild => {
    const infoAlerts: ReactNode = alerts.some(alert => alert.type === AlertType.info) ? (
      <div
        className='CampaignsTable__alert'
        onMouseEnter={() => {
          setHoverAlertForCampaign({ campaignId, alertType: AlertType.info })
        }}
        onMouseLeave={() => {
          setHoverAlertForCampaign(undefined)
        }}
      >
        <IconAlert alertType={AlertType.warning} />
      </div>
    ) : (
      <></>
    )

    const warnAlerts: ReactNode = alerts.some(alert => alert.type === AlertType.alert) ? (
      <div
        className='CampaignsTable__alert'
        onMouseEnter={() => {
          setHoverAlertForCampaign({ campaignId, alertType: AlertType.alert })
        }}
        onMouseLeave={() => {
          setHoverAlertForCampaign(undefined)
        }}
      >
        <IconAlert alertType={AlertType.alert} />
      </div>
    ) : (
      <></>
    )

    return alerts.length && alerts.some((alert: Alert) => alert.active) ? (
      <>
        <div className='CampaignTable__alerts-icons-container'>
          {infoAlerts}
          {warnAlerts}
        </div>
        {hoverAlertForCampaign?.campaignId?.includes(campaignId) && (
          <div>
            <Tooltip>
              {alerts
                .filter(el => el.active)
                .filter(el => el.type === hoverAlertForCampaign?.alertType)
                .map((alert, index) => (
                  <div
                    key={index}
                    className='Alerts__alert--hoverable'
                  >
                    <span>{index + 1}. </span>
                    {alert.message}
                  </div>
                ))}
            </Tooltip>
          </div>
        )}
      </>
    ) : (
      ''
    )
  }

  const Buttons = (
    campaignId: Campaign['id'],
    campaignName: Campaign['name'],
    campaignStatus: Campaign['status'],
    acceptanceStatus: Campaign['acceptanceStatus']
  ): ReactChild => (
    <>
      <>
        <div
          onPointerLeave={() => {
            setHoverForDisabledEdit(null)
          }}
          onMouseEnter={() => {
            setHoverForDisabledEdit(campaignId)
          }}
        >
          {hoverForDisabledEdit?.includes(campaignId) &&
            shouldDisableInSketch(campaignStatus, acceptanceStatus) && (
              <Tooltip>{t('campaignDetails.info.notAbleToEditWhenAccepting')}</Tooltip>
            )}
          <Button
            icon={faEdit}
            tooltip={t('common.edit')}
            dataCy={'edit-campaign-btn'}
            onClick={() => {
              if (campaignStatus === CampaignStatus.ACTIVE) {
                setEditModalIsOpenForCampaign(campaignId)
              } else {
                navigate(Routes.CAMPAIGNS.EDIT(campaignId))
              }
            }}
            disabled={
              (shouldDisableEditingForClient(campaignStatus, acceptanceStatus) ||
                !allowedFor({
                  template: CAMPAIGN_ACTION.EDIT,
                  feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
                  status: campaignStatus,
                })) &&
              !ActionButtonsHelper.getCanEditByOwner(campaignStatus, userData.withAllocatedTime)
            }
          />
        </div>
      </>
      <Button
        icon={faTrashAlt}
        tooltip={t('common.remove')}
        onClick={() => {
          setCurrentCampaign({ id: campaignId, name: campaignName })
          setIsRemoveModalOpen(true)
        }}
        disabled={
          !allowedFor({
            feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Delete },
            status: campaignStatus,
          })
        }
      />
    </>
  )

  const ProgressBar = (campaignId: Campaign['id'], progress: CampaignProgress) => {
    const { percent, status } = progress
    const width = `${percent >= 100 ? 100 : percent}%`

    const color = (): string => {
      switch (status) {
        case CampaignProgressStatus.OK: {
          return Colors.blue
        }
        case CampaignProgressStatus.PROBLEM: {
          return Colors.red
        }
      }
    }

    const progressStyle: CSSProperties = {
      width,
      backgroundColor: color(),
    }
    const tooltipStyle: CSSProperties = {
      position: 'relative',
      left: width,
    }

    return (
      <>
        <div className='CampaignTable__progress-bar'>
          <div
            className='CampaignTable__progress-bar--progress'
            style={progressStyle}
          ></div>
          <div className='CampaignTable__progress-bar--background'></div>
        </div>

        {hoverProgressForCampaign === campaignId && (
          <div style={tooltipStyle}>
            <Tooltip>{percent + '%'}</Tooltip>
          </div>
        )}
      </>
    )
  }

  const agencyTooltip = (campaignId: Campaign['id'], agencyName: string) => {
    return (
      <>
        {hoverAgencyName === campaignId && (
          <div className={'CampaignTable__agency-name--tooltip'}>
            <Tooltip>{agencyName}</Tooltip>
          </div>
        )}
      </>
    )
  }

  const CampaignTable: ReactChild = (
    <Table
      className='CommonTable'
      sort={tableFilter}
      onSort={({ column, direction }) =>
        void setTableFilter(Filter.CAMPAIGN, { column, direction })
      }
    >
      <TableHeader>
        <TableHeaderItem
          className='CommonTable__header-item'
          sortBy={CampaignSortColumn.NAME}
        >
          {t('common.name')}
        </TableHeaderItem>
        <TableHeaderItem
          className='CommonTable__header-item'
          sortBy={CampaignSortColumn.AGENCY_NAME}
        >
          {t('common.agency')}
        </TableHeaderItem>
        <TableHeaderItem
          className='CommonTable__header-item'
          sortBy={CampaignSortColumn.START_DATE}
        >
          {t('campaigns.time')}
        </TableHeaderItem>
        <TableHeaderItem
          className='CommonTable__header-item'
          sortBy={CampaignSortColumn.STATUS}
        >
          {t('common.status')}
        </TableHeaderItem>
        <>
          {allowedFor({ template: CAMPAIGN.ALERTS }) && (
            <TableHeaderItem
              className='CommonTable__header-item'
              sortBy={CampaignSortColumn.ALERTS}
            >
              {t('common.alert')}
            </TableHeaderItem>
          )}
        </>
        <TableHeaderItem
          className='CommonTable__header-item CampaignTable__header-item--wrapped'
          sortBy={CampaignSortColumn.COMMERCIAL_ATTRIBUTES}
        >
          {t('attributesSelection.commercialAttributesValues.best_effort')}
        </TableHeaderItem>
        <TableHeaderItem className='CommonTable__header-item'>
          {t('common.actions')}
        </TableHeaderItem>
      </TableHeader>

      <TableBody>
        {campaigns.map((campaign: Campaign) => {
          const date: string =
            DateUtils.parseAndFormat(campaign.startDate, DATE_FORMAT) +
            ' - ' +
            DateUtils.parseAndFormat(campaign.endDate, DATE_FORMAT)

          return (
            <TableRow
              key={campaign.id}
              className='CommonTable__data-row'
              onClick={(): void => void navigate(Routes.CAMPAIGNS.ONE(campaign.id))}
            >
              <TableData
                className='CommonTable__data-item CommonTable__data-item--name'
                onMouseEnter={() => {
                  setHoverProgressForCampaign(campaign.id)
                }}
                onMouseLeave={() => {
                  setHoverProgressForCampaign(undefined)
                }}
              >
                {campaign.name}
                {ProgressBar(campaign.id, campaign.progress)}
              </TableData>
              <TableData
                className='CommonTable__data-item CampaignTable__agency-name--td'
                onMouseEnter={(element: MouseEvent<HTMLElement>) => {
                  const currentTarget = element.currentTarget
                  const isOverflowing = currentTarget.clientWidth < currentTarget.scrollWidth
                  if (isOverflowing) {
                    setHoverAgencyName(campaign.id)
                  }
                }}
                onMouseLeave={() => {
                  setHoverAgencyName(undefined)
                }}
              >
                {campaign.agencyName}
                {hoverAgencyName && agencyTooltip(campaign.id, campaign.agencyName)}
              </TableData>
              <TableData className='CommonTable__data-item'>{date}</TableData>
              <TableData className='CommonTable__data-item'>
                {Status(campaign.status, campaign.acceptanceStatus)}
              </TableData>
              <>
                {allowedFor({ template: CAMPAIGN.ALERTS }) && (
                  <TableData className='CommonTable__data-item'>
                    {Alert(
                      [...campaign.alerts, ...campaign.aggregatedAcceptanceAlerts],
                      campaign.id
                    )}
                  </TableData>
                )}
              </>
              <TableData className='CommonTable__data-item'>
                {campaign.commercialAttribute === 'best_effort' && (
                  <FontAwesomeIcon icon={faCheck} />
                )}
              </TableData>
              <TableData className='CommonTable__data-item CommonTable__data-item--buttons'>
                {Buttons(campaign.id, campaign.name, campaign.status, campaign.acceptanceStatus)}
              </TableData>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )

  const CampaignTableContainer = (): ReactNode =>
    loading ? (
      <FillingSpinner type={FillingSpinnerType.STANDARD} />
    ) : campaigns.length > 0 ? (
      <>
        {CampaignTable}

        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={ITEMS_PER_PAGE}
          totalItemsCount={totalCount}
          onChange={setPageNumber}
        />
      </>
    ) : (
      t('common.noDataFound')
    )

  return (
    <div className='CommonTable__container'>
      <PageHeader
        allowedFor={allowedFor({
          feature: { name: FeatureName.Campaigns, action: CampaignPermissionType.Write },
        })}
        link={Routes.CAMPAIGNS.NEW}
        linkTooltip={t('common.createCampaign')}
      >
        <p>{t('common.campaigns')}</p>

        {allowedFor({
          template: CAMPAIGN_ACTION.BROAD_SIGN,
        }) && (
          <Button
            className='CampaignTable__broadsingn-button'
            icon={faSignsPost}
            tooltip={t('broadSign.add')}
            onClick={() => setIsBroadSignOpen(true)}
          />
        )}
      </PageHeader>

      <CampaignTableFilters
        onSubmit={() => {
          setPageNumber(1)
          setTrigger(true)
        }}
      />

      {CampaignTableContainer()}

      {currentCampaign && isRemoveModalOpen && (
        <ModalAction
          title={t('modal.campaignRemoval')}
          itemText={t('modal.doYouWantToRemove')}
          itemName={currentCampaign.name}
          actionText={t('common.remove')}
          isOpen={isRemoveModalOpen}
          setIsOpen={setIsRemoveModalOpen}
          isLoading={removeCampaignLoading}
          setIsLoading={setRemoveCampaignLoading}
          onAction={() =>
            service.deleteCampaign(currentCampaign.id, setTrigger, addNotification, t)
          }
        />
      )}

      {editModalIsOpenForCampaign && (
        <ModalAction
          title={t('modal.campaignEditing')}
          contentText={t('modal.editCampaignInfo')}
          itemText={t('modal.doYouWant')}
          itemName={t('modal.editCampaignItem')}
          actionText={t('common.edit')}
          isOpen={!!editModalIsOpenForCampaign}
          setIsOpen={() => void setEditModalIsOpenForCampaign(undefined)}
          onAction={() =>
            new Promise(() => void navigate(Routes.CAMPAIGNS.EDIT(editModalIsOpenForCampaign)))
          }
        />
      )}

      {isBroadSignModalOpen && (
        <BroadSignAddModal
          isOpen={isBroadSignModalOpen}
          setIsOpen={setIsBroadSignOpen}
        />
      )}
    </div>
  )
}

export default CampaignTable
