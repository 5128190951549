import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonSize, ButtonTheme } from 'components/Form/Button'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import Card from 'components/Layout/Card'
import InputSearch from 'components/Form/InputSearch'
import DatePickerSingle from 'components/Form/DatePickerSingle'
import { DATE_PLACEHOLDER } from 'constant'
import SelectMultiCheckbox from 'components/Form/SelectMultiCheckbox'
import { SelectOption } from 'components/Form/Select'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import './BroadsignFilters.scss'
import { broadSignActions } from '../../store/broad-sign-slice'

const BroadSignFilters: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { filters, dropdowns, showFilters } = useSelector((state: RootState) => state.broadSign)

  const SearchRow = (): ReactElement => (
    <div className='BroadSignFilters__search-row'>
      <Button
        theme={ButtonTheme.NAVY_BLUE_OUTLINE}
        size={ButtonSize.SMALL}
        icon={showFilters ? faCaretDown : faCaretUp}
        onClick={() => void dispatch(broadSignActions.toggleFiltersVisibility())}
      >
        {t('common.filters')}
      </Button>

      <InputSearch
        id='text'
        value={filters.text}
        onChange={text => {
          void dispatch(broadSignActions.setFilters({ ...filters, text }))
        }}
        onSubmit={text => {
          void dispatch(broadSignActions.setFilters({ ...filters, text }))
        }}
      />
    </div>
  )

  const Filters = (): ReactElement => (
    <Card className={'BroadSignFilters'}>
      <div className={'BroadSignFilters__container'}>
        <SelectMultiCheckbox
          id='statuses'
          title={t('common.status')}
          value={filters.statuses}
          options={[
            ...dropdowns.statuses.map((selectOption: string): SelectOption => {
              return {
                value: selectOption,
                label: t(`broadSign.${selectOption}`),
              }
            }),
          ]}
          onChange={(statuses): void => {
            void dispatch(broadSignActions.setFilters({ ...filters, statuses }))
          }}
          selectAllOption
        />

        <DatePickerSingle
          id='dateFrom'
          value={filters.dateFrom}
          placeholder={DATE_PLACEHOLDER}
          title={t('common.from')}
          onChange={(dateFrom): void => {
            void dispatch(broadSignActions.setFilters({ ...filters, dateFrom }))
          }}
        />
        <DatePickerSingle
          id='dateTo'
          value={filters.dateTo}
          placeholder={DATE_PLACEHOLDER}
          title={t('common.to')}
          onChange={(dateTo): void => {
            void dispatch(broadSignActions.setFilters({ ...filters, dateTo }))
          }}
        />
      </div>
    </Card>
  )

  return (
    <div className='CampaignTableFilters'>
      {SearchRow()}
      {showFilters && Filters()}
    </div>
  )
}

export default BroadSignFilters
