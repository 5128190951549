import { BROADSIGN_UPDATE_CAMPAIGN_BY_DEAL } from './broadSignUpdateCampaignByDeal'
import { UpdateCampaignByDealModel } from '../../pages/Campaign/CampaignTable/BroadSignAddModal/models/update-campaign-by-deal.model'
import { BroadSignFiltersModel } from './models/broad-sign-filters.model'
import { FetchResult } from '@apollo/client'
import { BroadSignCreationsResponseModel } from './models/broad-sign-creations-response.model'
import { BROADSIGN_CREATIONS } from './broadSignCreations'
import { client } from '../../components/ApolloProvider'
import { BroadSignUpdateCampaignByDealResponse } from '../../types/various'
import { ITEMS_PER_PAGE } from '../../constant'
import { BROADSIGN_ACCEPT_CREATION } from './broadSignAcceptCreation'
import { BroadSignCreationModel } from './models/broad-sign-creation.model'
import { BROADSIGN_REJECT_CREATION } from './broadSignRejectCreation'

const broadSignApi = {
  broadSignUpdateCampaignByDeal: ({
    dealId,
    campaignId,
  }: UpdateCampaignByDealModel): Promise<
    FetchResult<{ broadsignUpdateCampaignByDeal: BroadSignUpdateCampaignByDealResponse }>
  > =>
    client.mutate({
      mutation: BROADSIGN_UPDATE_CAMPAIGN_BY_DEAL,
      variables: {
        dealId,
        campaignId,
      },
    }),

  fetchBroadSignCreations: (
    filters: BroadSignFiltersModel,
    first: number
  ): Promise<FetchResult<{ broadsignCreations: BroadSignCreationsResponseModel }>> => {
    const { statuses, dateFrom, dateTo, text } = filters
    const mappedFilters = {
      statuses,
      ...(dateFrom && { dateFrom }),
      ...(dateTo && { dateTo }),
      ...(text && { text }),
    }

    return client.query({
      query: BROADSIGN_CREATIONS,
      variables: {
        filters: mappedFilters,
        first,
        last: ITEMS_PER_PAGE,
      },
    })
  },

  acceptCreation: (
    id: string
  ): Promise<
    FetchResult<{
      broadsignAcceptCreation: { broadsignCreation: Pick<BroadSignCreationModel, 'id' | 'status'> }
    }>
  > => {
    return client.mutate({
      mutation: BROADSIGN_ACCEPT_CREATION,
      variables: { id },
    })
  },

  rejectCreation: (
    id: string,
    rejectionReason: string
  ): Promise<
    FetchResult<{
      broadsignRejectCreation: { broadsignCreation: Pick<BroadSignCreationModel, 'id' | 'status'> }
    }>
  > => {
    return client.mutate({
      mutation: BROADSIGN_REJECT_CREATION,
      variables: { id, rejectionReason },
    })
  },
}

export default broadSignApi
