import Button, { ButtonTheme } from 'components/Form/Button'
import Modal from 'components/Modal'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './BroadSignAddModal.scss'
import FillingSpinner, { FillingSpinnerType } from '../../../../components/FillingSpinner'
import FormRow from '../../../../components/Form/FormRow'
import FormColumn from '../../../../components/Form/FormColumn'
import Input, { InputType } from '../../../../components/Form/Input'
import { broadSignFormDefault, BroadSignFormModel } from './models/broad-sign-form.model'
import { FormErrors } from '../../../../types/various'
import { UpdateCampaignByDealModel } from './models/update-campaign-by-deal.model'
import BroadSignAddModalService from './services/broad-sign-add-modal.service'
import { useNavigate } from 'react-router-dom'

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const BroadSignAddModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [broadSignForm, setBroadSignForm] = useState<BroadSignFormModel>(broadSignFormDefault)
  const [errors] = useState<FormErrors>()

  const disableSaveButton = () => {
    return (
      broadSignForm.dealId === '' ||
      broadSignForm.dealId === null ||
      broadSignForm.dealId === undefined
    )
  }

  const Footer = () => {
    return (
      <div className='Modal__footer BroadSignAddModal__footer'>
        <Button
          onClick={() => {
            const payload: UpdateCampaignByDealModel = {
              dealId: parseInt(broadSignForm.dealId),
              campaignId: broadSignForm.campaignId ? broadSignForm.campaignId : null,
            }

            void BroadSignAddModalService.broadSignUpdateCampaignByDeal(payload, navigate)

            setLoading(true)
            setIsOpen(false)
          }}
          disabled={loading || disableSaveButton()}
        >
          {t('common.save')}
        </Button>
        <Button
          theme={ButtonTheme.BLUE_OUTLINE}
          onClick={() => void setIsOpen(false)}
          disabled={loading}
        >
          {t('common.back')}
        </Button>
      </div>
    )
  }

  return (
    <Modal
      classNameChildren={
        loading ? 'BroadSignAddModal BroadSignAddModal--loading' : 'BroadSignAddModal'
      }
      title={t('broadSign.add')}
      isOpen={isOpen}
      onClose={setIsOpen}
      footer={Footer()}
      scrollable
    >
      {loading ? (
        <FillingSpinner type={FillingSpinnerType.STANDARD} />
      ) : (
        <div className={'BroadSignAddModalForm'}>
          <FormRow className={'BroadSignAddModalForm__deal'}>
            <FormColumn>
              <Input
                id='dealId'
                title={t('broadSign.dealId')}
                placeholder={t('broadSign.enterDealId')}
                type={InputType.NUMBER}
                value={broadSignForm.dealId}
                onChange={dealId => void setBroadSignForm({ ...broadSignForm, dealId })}
                disabled={loading}
                errors={errors?.dealId}
              />
            </FormColumn>
          </FormRow>

          <FormRow className={'BroadSignAddModalForm__campaign'}>
            <FormColumn>
              <Input
                id='campaignId'
                title={t('broadSign.campaignId')}
                placeholder={t('broadSign.enterCampaignId')}
                value={broadSignForm.campaignId}
                onChange={campaignId => void setBroadSignForm({ ...broadSignForm, campaignId })}
                disabled={loading}
                errors={errors?.campaignId}
              />
            </FormColumn>
          </FormRow>
        </div>
      )}
    </Modal>
  )
}

export default BroadSignAddModal
